<div mat-dialog-title fxLayout="column" fxLayoutAlign="space-between flex-start">
	<div fxLayout="row" fxLayoutAlign="space-between top" class="fullwidth">
		<ng-container *ngIf="!project.tandemMode">
			<div *ngIf="!(project && project.maxBadgeGrantee > 1)">
				{{ "bagde_editor.give_badge" | translate }}
			</div>

			<mat-tab-group *ngIf="project && project.maxBadgeGrantee > 1" (selectedTabChange)="onTabChanged($event)">
				<mat-tab label="Individual"></mat-tab>
				<mat-tab label="Grupal"></mat-tab>
			</mat-tab-group>
		</ng-container>

		<div *ngIf="project.tandemMode" fxLayout="column" class="gap-1">
			<h3>{{ "bagde_editor.give_badge" | translate }}</h3>

			<mat-tab-group (selectedTabChange)="onTabChanged({ index: $event.index + 2 })">
				<!-- Para no modificar el resto del código. Tabs 2, 3 y 4 -->
				@if (project.id == 473) {
					<mat-tab label="TËCNICOS"></mat-tab>
					<mat-tab label="TEC / DIRECTOR"></mat-tab>
					<mat-tab label="ETIs"></mat-tab>
				} @else {
					<mat-tab label="Alumnos"></mat-tab>
					<mat-tab label="Formadores"></mat-tab>
					<mat-tab label="ETIs"></mat-tab>
				}
			</mat-tab-group>
		</div>

		<button mat-icon-button mat-dialog-close style="color: #666">
			<mat-icon>close</mat-icon>
		</button>
	</div>

	<div *ngIf="!project.tandemMode" class="fullwidth" style="position: relative; margin-top: 5px">
		<mat-form-field subscriptSizing="dynamic" class="search-user w-100-p">
			<input
				matInput
				placeholder="{{ 'badge_editor.search_people_placeholder' | translate }}"
				[(ngModel)]="filterPersonValue"
				(keyup)="filterPersons($event)"
			/>
			<mat-icon *ngIf="!personSelected" matSuffix class="s-18">search</mat-icon>
			<button mat-button *ngIf="filterPersonValue.length > 0" matSuffix mat-icon-button aria-label="Clear" (click)="clearPerson()">
				<mat-icon>close</mat-icon>
			</button>
		</mat-form-field>

		<div *ngIf="persons.length > 0 && !personSelected" class="wrap-persons">
			<div class="list-persons">
				<div *ngFor="let person of persons" fxlayout="row" fxLayoutAlign="flex-start center" class="item-person" (click)="selectPerson(person)">
					<div class="person-avatar avatar-50 mr-8" fxLayout="row" fxLayoutAlign="center center">
						<img class="img-circle" [src]="getImagePerson(person.username)" [title]="person.username" />
					</div>
					<div fxLayout="column" class="info-person">
						<span class="m-0 font-size-14">{{ "@" + person.username }}</span>
						<span class="m-0 font-size-14"
							><strong>{{ person.displayName }}</strong></span
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- ############## BADGE INDIVIDUAL ############# -->
<mat-dialog-content class="mat-typography" *ngIf="badgeTab == 0">
	<!-- <span>Badge RETURN {{badgeReturn}}</span> -->

	<div *ngIf="!personSelected">
		<p class="m-0 mb-8">
			<b>{{ "bagde_editor.other_user" | translate }}</b>
		</p>
		<div fxLayout="row wrap">
			<div
				*ngFor="let person of personsSuggestion"
				fxFlex="50"
				fxlayout="row"
				fxLayoutAlign="flex-start center"
				class="btn-select-person p-12"
				(click)="selectPerson(person)"
			>
				<div *ngIf="person" class="person-avatar avatar-50 mr-8" fxLayout="row" fxLayoutAlign="center center">
					<img class="img-circle" [src]="getImagePerson(person.username)" [title]="person.username" />
				</div>
				<div *ngIf="person" fxLayout="column" class="info-person">
					<h4 class="m-0 font-size-18 text-theme">{{ person.displayName }}</h4>
					<small class="m-0 font-size-12">{{ getPuesto(person) }}</small>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="personSelected">
		<div class="badge-responsive-container" fxLayout="row">
			<div fxLayout="column" fxLayoutAlign="flex-start center" class="pr-20" style="width: 32%; min-width: 140px">
				<div class="person-avatar avatar-132" fxLayout="row" fxLayoutAlign="center center">
					<img class="img-circle" [src]="getImagePersonGrande(personSelected.username)" [title]="personSelected.username" />
					<img
						*ngIf="competencySelected && competencySelected.type_competency === 0"
						class="badge-selected animated zoomIn"
						[src]="getImageCompetency(competencySelected.id)"
						[title]="competencySelected.name"
					/>
					<img
						*ngIf="competencySelected && competencySelected.type_competency !== 0"
						class="badge-selected animated zoomIn"
						[src]="getImageDefaultMission()"
						[title]="competencySelected.name"
					/>
				</div>

				<div *ngIf="project.badge_type">
					<h5 class="m-0 mb-8 mt-12 title-card-gray text-theme text-center">
						<b>{{ "bagde_editor.mission" | translate }}</b>
					</h5>
					<p class="m-0 font-size-14 text-center">{{ getMision(personSelected) }}</p>

					<ng-container *ngIf="competencySelected">
						<h5 class="m-0 mb-8 mt-12 title-card-gray text-theme text-center">
							<b>Selección</b>
						</h5>
						<p class="m-0 font-size-14 text-center">{{ competencySelected.name }}</p>
					</ng-container>
				</div>
			</div>

			<div fxFlex="1 1 0" fxLayout="row" class="pl-20">
				<div *ngIf="badgeStep === 1 && !project.badge_type" class="competency-main" fxLayout="column">
					<span class="m-0 font-size-14">{{ "badge_editor.mission.value.title2" | translate }}</span>
					<div fxLayout="row wrap" fxLayoutAlign="space-around center" class="competency-list mt-8 px-20">
						<div
							fxFlex="1 0 auto"
							*ngFor="let competency of competencies.valores"
							fxLayoutAlign="center center"
							class="animated zoomIn"
							[title]="competency.name"
							(click)="selectCompetency(competency)"
						>
							<img [src]="getImageCompetency(competency.id)" style="max-width: 50px; max-height: 50px" />
						</div>
					</div>
				</div>

				<ng-container *ngIf="project.badge_type">
					<div *ngIf="badgeStep === 1" class="competency-main" fxLayout="column">
						<span class="m-0 font-size-14">{{ "badge_editor.mission.title" | translate }}</span>
						<div fxLayout="row wrap" fxLayoutAlign="space-between center" class="competency-list">
							<div
								fxFlex="1 0 auto"
								*ngFor="let competency of competencies.misionPuesto"
								class="mision-select mt-12 text-center animated zoomIn"
								[title]="competency.name"
								(click)="selectCompetency(competency)"
							>
								<span class="">{{ competency.name }}</span>
							</div>
						</div>

						<div *ngIf="competencies.misionEmpresa?.length || competencies.valores?.length" class="mt-16">
							<div fxLayout="row" fxLayoutAlign="flex-start flex-start" style="cursor: pointer" (click)="badgeStep = 2">
								<mat-icon class="s-16 mr-4 pt-4 text-theme">add_circle</mat-icon>
								<a class="m-0" style="color: #4f4d50">{{ "badge_editor.mission.other.title" | translate }}</a>
							</div>
						</div>
					</div>

					<div *ngIf="badgeStep === 2" class="competency-extra" fxLayout="column">
						<span *ngIf="competencies.misionEmpresa?.length" class="m-0 font-size-14">{{ "badge_editor.mission.company.title" | translate }}</span>
						<div fxLayout="row wrap" fxLayoutAlign="space-between center" class="competency-list">
							<div
								fxFlex="1 0 auto"
								*ngFor="let competency of competencies.misionEmpresa"
								class="mision-select mt-12 text-center animated zoomIn"
								[title]="competency.name"
								(click)="selectCompetency(competency)"
								style="max-width: 120px"
							>
								<span class="">{{ competency.name }}</span>
							</div>
						</div>

						<span *ngIf="competencies.valores?.length" class="m-0 font-size-14 mt-16">{{ "badge_editor.mission.value.title" | translate }}</span>
						<div fxLayout="row wrap" fxLayoutAlign="space-around center" class="competency-list mt-8 px-20">
							<div
								fxFlex="1 0 auto"
								*ngFor="let competency of competencies.valores"
								fxLayoutAlign="center center"
								class="animated zoomIn"
								[title]="competency.name"
								(click)="selectCompetency(competency)"
							>
								<img [src]="getImageCompetency(competency.id)" style="max-width: 50px; max-height: 50px" />
							</div>
						</div>

						<div class="mt-12" *ngIf="project.id != 10 && project.id != 4">
							<div fxLayout="row" fxLayoutAlign="flex-start flex-start" style="cursor: pointer" (click)="badgeStep = 1">
								<mat-icon class="s-16 mr-4 pt-4 text-theme">arrow_back</mat-icon>
								<a class="m-0" style="color: #4f4d50">{{ "badge_editor.change_competency" | translate }}</a>
							</div>
						</div>
					</div>
				</ng-container>

				<div *ngIf="badgeStep === 3" fxLayout="column" class="w-100-p">
					<div class="animated fadeInRight">
						<div class="mb-12">
							<div
								fxLayout="row"
								fxLayoutAlign="flex-start flex-start"
								style="cursor: pointer"
								(click)="badgeStep = 1; competencySelected = null; motivation = ''"
							>
								<mat-icon class="s-16 mr-4 pt-4 text-theme">arrow_back</mat-icon>
								<a class="m-0" style="color: #4f4d50" *ngIf="project.badge_type">{{ "badge_editor.change_competency" | translate }}</a>
								<a class="m-0" style="color: #4f4d50" *ngIf="!project.badge_type">{{ "badge_editor.mission.back" | translate }}</a>
							</div>
						</div>

						<div *ngIf="competencySelected.type_competency === 0 || project.id == 288" class="mb-16">
							<!-- Visible solo para comptencia de tipo Valor -->
							<span class="m-0 font-size-14">{{ competencySelected.type_competency === 0 ? "Indicadores" : "Comportamientos" }}</span>
							<div class="kpi-table">
								<div *ngFor="let indicator of indicators" class="my-8">
									<input
										id="badgeIndicator{{ indicator.id }}"
										type="checkbox"
										[(ngModel)]="indicator.isChecked"
										(change)="calNumIndicatorSelected()"
									/>
									<label for="badgeIndicator{{ indicator.id }}">{{ indicator.name }}</label>
								</div>
							</div>

							<span
								*ngIf="minIndicators > 0 && minIndicators !== maxIndicators"
								[style]="
									minIndicators > numIndicatorsSelected || numIndicatorsSelected > maxIndicators ? 'visibility:visible;' : 'visibility:hidden;'
								"
								class="mt-12 font-size-14"
								style="color: red"
								>Debe seleccionar {{ minIndicators }} o {{ maxIndicators }}
								{{ competencySelected.type_competency === 0 ? "indicadores" : "comportamientos" }}</span
							>
							<span
								*ngIf="minIndicators > 0 && minIndicators === maxIndicators"
								[style]="
									minIndicators > numIndicatorsSelected || numIndicatorsSelected > maxIndicators ? 'visibility:visible;' : 'visibility:hidden;'
								"
								class="mt-12 font-size-14"
								style="color: red"
								>Debe seleccionar únicamente {{ minIndicators }}
								{{ competencySelected.type_competency === 0 ? "indicadores" : "comportamientos" }}</span
							>
						</div>
					</div>

					<div fxLayout="row" fxLayoutAlign="flex-start" class="w-100-p">
						<fb-emoji-picker position="fixed" (emojiSel)="emojiSelected($event)"></fb-emoji-picker>

						<div fxLayout="column" class="w-100-p">
							<textarea
								class="form-control w-100-p"
								[(ngModel)]="motivation"
								style="height: 60px"
								placeholder="{{ 'badge_editor.motive.placeholder' | translate }}"
							></textarea>
							<span [style]="motivation.length < minCharacters ? 'visibility:visible;' : 'visibility:hidden;'" class="text-muted"
								>{{ "" | translate }}Faltan {{ minCharacters - motivation.length }} {{ "" | translate }}caracteres</span
							>
						</div>
					</div>

					<div fxFlex="1 1 0" fxLayout="row" fxLayoutAlign="flex-end flex-end" class="mt-16">
						<!-- TODO:VYGON Pasar al config -->
						<button
							*ngIf="project.id == 288 && competencySelected.type_competency === 1"
							mat-button
							mat-dialog-close
							class="btn-feedbalia mr-12"
							[disabled]="checkValidData()"
							(click)="sendBadge(true)"
						>
							{{ "badge_editor.send_badge2" | translate }}
						</button>
						<button mat-button mat-dialog-close class="btn-feedbalia" [disabled]="checkValidData()" (click)="sendBadge(false)">
							{{ "badge_editor.send_badge" | translate }}
						</button>
						<!-- <button mat-button mat-dialog-close class="btn-feedbalia" [disabled]="checkValidData()" (click)="sendBadge(false)">
                            Entregar Medalla
                        </button> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</mat-dialog-content>

<!-- ############## BADGE GRUPAL ############# -->
<mat-dialog-content class="mat-typography" *ngIf="badgeTab == 1">
	<div *ngIf="!personsSelected.length > 0">
		<p class="m-0 mb-8">
			<b>{{ "bagde_editor.select_user" | translate }}</b>
		</p>
		<div fxLayout="row wrap">
			<div
				*ngFor="let person of personsSuggestion"
				fxFlex="50"
				fxlayout="row"
				fxLayoutAlign="flex-start center"
				class="btn-select-person p-12"
				(click)="selectPerson(person)"
			>
				<div class="person-avatar avatar-50 mr-8" fxLayout="row" fxLayoutAlign="center center">
					<img class="img-circle" [src]="getImagePerson(person.username)" [title]="person.username" />
				</div>
				<div fxLayout="column" class="info-person">
					<h4 class="m-0 font-size-18 text-theme">{{ person.displayName }}</h4>
					<small class="m-0 font-size-12">{{ getPuesto(person) }}</small>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="personsSelected.length > 0">
		<div *ngIf="badgeStep === 1" fxLayout="row" fxLayoutAlign="space-between flex-start">
			<mat-chip-listbox aria-label="Personas" class="chipPersonas">
				<mat-chip-option
					*ngFor="let person of personsSelected; let i = index"
					[removable]="true"
					(removed)="unselectPersonArray(i)"
					class="personChip"
					[selectable]="false"
				>
					<div fxLayout="row" class="gap-1" fxLayoutAlign="start center">
						<img class="img-circle imgChip" style="width: 30px !important" [src]="getImagePerson(person.username)" [title]="person.username" />
						<b class="text-truncate">{{ person.displayName }}</b>
					</div>
					<mat-icon matChipRemove>cancel</mat-icon>
				</mat-chip-option>
			</mat-chip-listbox>
			<div fxLayout="column" style="text-align: center">
				<span class="m-0 font-size-14">{{ "badge_editor.select_competency" | translate }}</span>
				<div fxLayout="row wrap" fxLayoutAlign="space-around center" class="competency-list mt-8 px-20">
					<div
						fxFlex="1 0 auto"
						*ngFor="let competency of competencies.valores"
						fxLayoutAlign="center center"
						class="animated zoomIn"
						[title]="competency.name"
						(click)="selectCompetency(competency)"
					>
						<img [src]="getImageCompetency(competency.id)" class="competencyImage" />
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="badgeStep === 3" fxLayoutAlign="middle flex-start" fxLayout="row" class="w-100-p">
			<div fxLayout="column" class="chipPersonas">
				<img
					*ngIf="competencySelected && competencySelected.type_competency === 0"
					class="badge-selected animated zoomIn competencyImageEnd"
					[src]="getImageCompetency(competencySelected.id)"
					[title]="competencySelected.name"
				/>
				<mat-chip-listbox aria-label="Personas">
					<mat-chip-option
						*ngFor="let person of personsSelected; let i = index"
						[removable]="true"
						(removed)="unselectPersonArray(i)"
						class="personChip"
						[selectable]="false"
					>
						<div fxLayout="row" class="gap-1" fxLayoutAlign="start center">
							<img class="img-circle imgChip" style="width: 30px !important" [src]="getImagePerson(person.username)" [title]="person.username" />
							<b class="text-truncate">{{ person.displayName }}</b>
						</div>
						<mat-icon matChipRemove>cancel</mat-icon>
					</mat-chip-option>
				</mat-chip-listbox>
			</div>

			<div fxLayout="column" class="w-100-p">
				<div class="animated fadeInRight">
					<div class="mb-12">
						<div
							fxLayout="row"
							fxLayoutAlign="flex-start flex-start"
							style="cursor: pointer"
							(click)="badgeStep = 1; competencySelected = null; motivation = ''"
						>
							<mat-icon class="s-16 mr-4 pt-4 text-theme">arrow_back</mat-icon>
							<a class="m-0" style="color: #4f4d50">{{ "badge_editor.competency.change" | translate }}</a>
						</div>
					</div>

					<div *ngIf="competencySelected.type_competency === 0" class="mb-16">
						<!-- Visible solo para comptencia de tipo Valor -->
						<span class="m-0 font-size-14">Indicadores</span>
						<div class="kpi-table">
							<div *ngFor="let indicator of indicators" class="my-8">
								<input id="badgeIndicator{{ indicator.id }}" type="checkbox" [(ngModel)]="indicator.isChecked" (change)="calNumIndicatorSelected()" />
								<label for="badgeIndicator{{ indicator.id }}">{{ indicator.name }}</label>
							</div>
						</div>

						<span
							*ngIf="minIndicators > 0 && minIndicators !== maxIndicators"
							[style]="minIndicators > numIndicatorsSelected || numIndicatorsSelected > maxIndicators ? 'visibility:visible;' : 'visibility:hidden;'"
							class="mt-12 font-size-14"
							style="color: red"
							>{{ "" | translate }}Debe seleccionar {{ minIndicators }} o {{ maxIndicators }} {{ "" | translate }}indicadores</span
						>
						<span
							*ngIf="minIndicators > 0 && minIndicators === maxIndicators"
							[style]="minIndicators > numIndicatorsSelected ? 'visibility:visible;' : 'visibility:hidden;'"
							class="mt-12 font-size-14"
							style="color: red"
							>{{ "" | translate }}Debe seleccionar al menos {{ minIndicators }} {{ "" | translate }}indicadores</span
						>
					</div>
				</div>

				<div fxLayout="column">
					<textarea
						class="form-control"
						[(ngModel)]="motivation"
						style="height: 60px"
						placeholder="¿Por qué le darás este agradecimiento?"
					></textarea>
					<span [style]="motivation.length < minCharacters ? 'visibility:visible;' : 'visibility:hidden;'" class="text-muted"
						>{{ "" | translate }}Faltan {{ minCharacters - motivation.length }} {{ "" | translate }}caracteres</span
					>
				</div>

				<div fxFlex="1 1 0" fxLayout="row" fxLayoutAlign="flex-end flex-end" class="mt-16">
					<button mat-button mat-dialog-close class="btn-feedbalia" [disabled]="checkValidData()" (click)="sendBadge(false)">
						{{ "badge_editor.send_badge" | translate }}
					</button>
				</div>
			</div>
		</div>
	</div>
</mat-dialog-content>

<!-- ############## TANDEM ############# -->
<mat-dialog-content *ngIf="project.tandemMode">
	<div *ngIf="!personSelected">
		<div fxLayout="row wrap">
			<div
				*ngFor="let person of personsTab | async"
				fxlayout="row"
				fxLayoutAlign="start center"
				class="btn-select-person p-12 gap-1"
				(click)="selectPerson(person)"
				style="width: 50%"
			>
				<div *ngIf="person" class="person-avatar avatar-50 mr-8" fxLayout="row" fxLayoutAlign="center center">
					<img class="img-circle" [src]="getImagePerson(person.nickname)" [title]="person.nickname" />
				</div>

				<div *ngIf="person" fxLayout="column" class="info-person">
					<h4 class="m-0 font-size-18 text-theme">{{ person.displayName }}</h4>
					<!-- <small class="m-0 font-size-12">{{getPuesto(person)}}</small> -->
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="personSelected">
		<div class="badge-responsive-container" fxLayout="row">
			<div fxLayout="column" fxLayoutAlign="flex-start center" class="pr-20" style="width: 32%; min-width: 140px">
				<div class="person-avatar avatar-132" fxLayout="row" fxLayoutAlign="center center">
					<img class="img-circle" [src]="getImagePersonGrande(personSelected.nickname)" [title]="personSelected.nickname" />
					<ng-container *ngIf="!project.tandemMode">
						<img
							*ngIf="competencySelected && competencySelected.type_competency === 0"
							class="badge-selected animated zoomIn"
							[src]="getImageCompetency(competencySelected.id)"
							[title]="competencySelected.name"
						/>
						<img
							*ngIf="competencySelected && competencySelected.type_competency !== 0"
							class="badge-selected animated zoomIn"
							[src]="getImageDefaultMission()"
							[title]="competencySelected.name"
						/>
					</ng-container>
					<ng-container *ngIf="project.tandemMode">
						<img
							*ngIf="competencySelected && competencySelected.image"
							class="badge-selected animated zoomIn"
							[src]="getImageCompetency(competencySelected.id)"
							[title]="competencySelected.name"
						/>
					</ng-container>
				</div>

				<div *ngIf="project.badge_type">
					<h5 class="m-0 mb-8 mt-12 title-card-gray text-theme text-center">
						<b>{{ "bagde_editor.mission" | translate }}</b>
					</h5>
					<p class="m-0 font-size-14 text-center">{{ getMision(personSelected) }}</p>
					<span *ngIf="competencySelected">{{ competencySelected.name }}</span>
				</div>
			</div>

			<div fxFlex="1 1 0" fxLayout="row" class="pl-20">
				<div *ngIf="badgeStep === 1 && !project.badge_type" class="competency-main" fxLayout="column">
					<span class="m-0 font-size-14">{{ "badge_editor.mission.value.title2" | translate }}</span>
					<div fxLayout="row wrap" fxLayoutAlign="space-around center" class="competency-list mt-8 px-20">
						<div
							fxFlex="1 0 auto"
							*ngFor="let competency of competencies.valores"
							fxLayoutAlign="center center"
							class="animated zoomIn"
							[title]="competency.name"
							(click)="selectCompetency(competency)"
						>
							<img [src]="getImageCompetency(competency.id)" style="max-width: 50px; max-height: 50px" />
						</div>
					</div>
				</div>

				<ng-container *ngIf="project.badge_type">
					<div *ngIf="badgeStep === 1" class="competency-main" fxLayout="column">
						<span class="m-0 font-size-14">{{ "badge_editor.mission.title" | translate }}</span>
						<div fxLayout="row wrap" fxLayoutAlign="space-between center" class="competency-list">
							<div
								fxFlex="1 0 auto"
								*ngFor="let competency of competencies.misionPuesto"
								class="mision-select mt-12 text-center animated zoomIn"
								[title]="competency.name"
								(click)="selectCompetency(competency)"
								style="width: 100%"
							>
								<span class="">{{ competency.name }}</span>
							</div>
						</div>

						<div *ngIf="competencies.misionEmpresa?.length || competencies.valores?.length" class="mt-16">
							<div fxLayout="row" fxLayoutAlign="flex-start flex-start" style="cursor: pointer" (click)="badgeStep = 2">
								<mat-icon class="s-16 mr-4 pt-4 text-theme">add_circle</mat-icon>
								<a class="m-0" style="color: #4f4d50">{{ "badge_editor.mission.other.title" | translate }}</a>
							</div>
						</div>
					</div>

					<div *ngIf="badgeStep === 2" class="competency-extra" fxLayout="column">
						<span *ngIf="competencies.misionEmpresa?.length" class="m-0 font-size-14">{{ "badge_editor.mission.company.title" | translate }}</span>
						<div fxLayout="row wrap" fxLayoutAlign="space-between center" class="competency-list">
							<div
								fxFlex="1 0 auto"
								*ngFor="let competency of competencies.misionEmpresa"
								class="mision-select mt-12 text-center animated zoomIn"
								[title]="competency.name"
								(click)="selectCompetency(competency)"
								style="max-width: 120px"
							>
								<span class="">{{ competency.name }}</span>
							</div>
						</div>

						<span *ngIf="competencies.valores?.length" class="m-0 font-size-14 mt-16">{{ "badge_editor.mission.value.title" | translate }}</span>
						<div fxLayout="row wrap" fxLayoutAlign="space-around center" class="competency-list mt-8 px-20">
							<div
								fxFlex="1 0 auto"
								*ngFor="let competency of competencies.valores"
								fxLayoutAlign="center center"
								class="animated zoomIn"
								[title]="competency.name"
								(click)="selectCompetency(competency)"
							>
								<img [src]="getImageCompetency(competency.id)" style="max-width: 50px; max-height: 50px" />
							</div>
						</div>

						<div class="mt-12" *ngIf="project.id != 10 && project.id != 4">
							<div fxLayout="row" fxLayoutAlign="flex-start flex-start" style="cursor: pointer" (click)="badgeStep = 1">
								<mat-icon class="s-16 mr-4 pt-4 text-theme">arrow_back</mat-icon>
								<a class="m-0" style="color: #4f4d50">{{ "badge_editor.change_competency" | translate }}</a>
							</div>
						</div>
					</div>
				</ng-container>

				<div *ngIf="badgeStep === 3" fxLayout="column" class="w-100-p">
					<div class="animated fadeInRight">
						<div class="mb-12">
							<div
								fxLayout="row"
								fxLayoutAlign="flex-start flex-start"
								style="cursor: pointer"
								(click)="badgeStep = 1; competencySelected = null; motivation = ''"
							>
								<mat-icon class="s-16 mr-4 pt-4 text-theme">arrow_back</mat-icon>
								<a class="m-0" style="color: #4f4d50" *ngIf="project.badge_type">{{ "badge_editor.change_competency" | translate }}</a>
								<a class="m-0" style="color: #4f4d50" *ngIf="!project.badge_type">{{ "badge_editor.mission.back" | translate }}</a>
							</div>
							<div fxLayout="row" fxLayoutAlign="flex-start flex-start" *ngIf="project.tandemMode">
								<span class="m-0 font-size-14">{{ competencySelected.description }}</span>
							</div>
						</div>

						<div *ngIf="competencySelected.type_competency === 0 || project.id == 288" class="mb-16">
							<!-- Visible solo para comptencia de tipo Valor -->
							<span class="m-0 font-size-14">{{ competencySelected.type_competency === 0 ? "Indicadores" : "Comportamientos" }}</span>
							<div class="kpi-table">
								<div *ngFor="let indicator of indicators" class="my-8">
									<input
										id="badgeIndicator{{ indicator.id }}"
										type="checkbox"
										[(ngModel)]="indicator.isChecked"
										(change)="calNumIndicatorSelected()"
									/>
									<label for="badgeIndicator{{ indicator.id }}">{{ indicator.name }}</label>
								</div>
							</div>

							<span
								*ngIf="minIndicators > 0 && minIndicators !== maxIndicators"
								[style]="
									minIndicators > numIndicatorsSelected || numIndicatorsSelected > maxIndicators ? 'visibility:visible;' : 'visibility:hidden;'
								"
								class="mt-12 font-size-14"
								style="color: red"
								>Debe seleccionar {{ minIndicators }} o {{ maxIndicators }}
								{{ competencySelected.type_competency === 0 ? "indicadores" : "comportamientos" }}</span
							>
							<span
								*ngIf="minIndicators > 0 && minIndicators === maxIndicators"
								[style]="
									minIndicators > numIndicatorsSelected || numIndicatorsSelected > maxIndicators ? 'visibility:visible;' : 'visibility:hidden;'
								"
								class="mt-12 font-size-14"
								style="color: red"
								>Debe seleccionar únicamente {{ minIndicators }}
								{{ competencySelected.type_competency === 0 ? "indicadores" : "comportamientos" }}</span
							>
						</div>
					</div>

					<div fxLayout="row" fxLayoutAlign="flex-start" class="w-100-p">
						<fb-emoji-picker position="fixed" (emojiSel)="emojiSelected($event)"></fb-emoji-picker>

						<div fxLayout="column" class="w-100-p">
							<textarea
								class="form-control w-100-p"
								[(ngModel)]="motivation"
								style="height: 60px"
								placeholder="{{ 'badge_editor.motive.placeholder' | translate }}"
							></textarea>
							<span [style]="motivation.length < minCharacters ? 'visibility:visible;' : 'visibility:hidden;'" class="text-muted"
								>{{ "" | translate }}Faltan {{ minCharacters - motivation.length }} {{ "" | translate }}caracteres</span
							>
						</div>
					</div>

					<div fxFlex="1 1 0" fxLayout="row" fxLayoutAlign="flex-end flex-end" class="mt-16">
						<button mat-button mat-dialog-close class="btn-feedbalia" [disabled]="checkValidData()" (click)="sendBadge(false)">
							{{ "badge_editor.send_badge" | translate }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</mat-dialog-content>
